import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.conference.new');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-conference-new',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.conference.new/view.scss */
/* src/app/portal.seminify.conference.new/view.scss: no such file or directory */`],
})
export class PortalSeminifyConferenceNewComponent implements OnInit {

    public data: any = {};

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async create() {
        const { code, data } = await wiz.call("create", this.data);
        if (code == 200) {
            await this.service.href(`/conference/${data}`);
            location.reload();
        }
    }

}

export default PortalSeminifyConferenceNewComponent;