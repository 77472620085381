import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.form.dropdown');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { ContentChild, TemplateRef } from '@angular/core';
import { HostListener } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-season-form-dropdown',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.season.form.dropdown/view.scss */
.tw-dropdown-menu {
  display: none;
}
.tw-dropdown-menu.show {
  display: block;
}`],
})
export class PortalSeasonFormDropdownComponent implements OnInit {
    @Input() menuStyle: any = {};

    constructor(@Inject( Service) public service: Service) { }

    public isOpen: boolean = false;

    @ContentChild('button') button: TemplateRef<any>;
    @ContentChild('menu') menu: TemplateRef<any>;

    public async ngOnInit() { }

    public async toggle(stat: any = null) {
        if (stat !== null) {
            this.isOpen = stat;
        } else {
            this.isOpen = !this.isOpen;
        }
        await this.service.render();
    }

    @HostListener('document:click')
    public clickout() {
        if (this.isOpen)
            this.isOpen = false;
        this.service.render();
    }
}

export default PortalSeasonFormDropdownComponent;