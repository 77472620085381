import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.widget.conference.survey');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-widget-conference-survey',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.widget.conference.survey/view.scss */
/* src/app/portal.seminify.widget.conference.survey/view.scss: no such file or directory */`],
})
export class PortalSeminifyWidgetConferenceSurveyComponent implements OnInit {
    @Input() id: any;
    @Input() data: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        if (!this.data.conference.extra.survey) this.data.conference.extra.survey = [];
    }

    public async add() {
        let preId = {};
        for (let i = 0; i < this.data.conference.extra.survey.length; i++)
            preId[this.data.conference.extra.survey[i].id] = true;
        let id: any = this.service.random(4);
        while (preId[id])
            id = this.service.random(4);
        this.data.conference.extra.survey.push({ id: id, type: 'score', title: '', description: '' });
        await this.service.render();
    }

    public async delete(item) {
        let res = await this.service.modal.error("정말로 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        this.data.conference.extra.survey.remove(item);
        await this.service.render();
    }

}

export default PortalSeminifyWidgetConferenceSurveyComponent;