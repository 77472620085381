import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.widget.conference.member');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-widget-conference-member',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.widget.conference.member/view.scss */
/* src/app/portal.seminify.widget.conference.member/view.scss: no such file or directory */`],
})
export class PortalSeminifyWidgetConferenceMemberComponent implements OnInit {

    @Input() id: any;
    @Input() data: any;

    public item: any = { role: 'user' };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load('accept');
    }

    public async load(status) {
        this.tab = status;
        let conference_id = this.data.conference.id;
        const { data } = await wiz.call("load", { conference_id: conference_id, status: status });
        this.data.member = data;
        await this.service.render();
    }

    public async update(item) {
        let conference_id = this.data.conference.id;
        if (item.id)
            await wiz.call("update", { conference_id: conference_id, user_id: item.user_id, role: item.role });
        else {
            await wiz.call("update", { conference_id: conference_id, email: item.email, role: item.role });
            this.item = { role: 'user' };
        }

        await this.load(this.tab);
    }

    public async response(item) {
        let conference_id = this.data.conference.id;
        await wiz.call("response", { conference_id: conference_id, user_id: item.user_id, status: item.status });
        await this.load(this.tab);
    }

    public async delete(item) {
        let res = await this.service.modal.error("참가자 목록에서 사용자를 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        let conference_id = this.data.conference.id;
        await wiz.call("delete", { conference_id: conference_id, user_id: item.user_id });
        await this.load(this.tab);
    }

    public tab: any = 'accept';

    public activeClass(status: string) {
        if (status == this.tab) {
            return "group flex gap-x-3 rounded-md bg-indigo-50 py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-indigo-600";
        }
        return "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600";
    }


}

export default PortalSeminifyWidgetConferenceMemberComponent;