import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.widget.seminar.member');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-widget-seminar-member',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.widget.seminar.member/view.scss */
/* src/app/portal.seminify.widget.seminar.member/view.scss: no such file or directory */`],
})
export class PortalSeminifyWidgetSeminarMemberComponent implements OnInit {
    @Input() conference: any;
    @Input() seminar: any;
    @Input() userdata: any;
    @Input() event: any;

    public data: any = { member: [] };
    public item: any = { role: 'presenter' };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async request(uri: string, data: any = {}) {
        data.conference_id = this.conference.id
        data.seminar_id = this.seminar.id
        return await wiz.call(uri, data);
    }

    public async load() {
        const { data } = await this.request("load");
        this.data.member = data;
        await this.service.render();
    }

    public async update(item) {
        if (item.id)
            await this.request("update", { user_id: item.user_id, role: item.role });
        else {
            await this.request("update", { email: item.email, role: item.role });
            this.item = { role: 'presenter' };
        }
        await this.load();
    }

    public async delete(item) {
        let res = await this.service.modal.error("참가자 목록에서 사용자를 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        await this.request("delete", { user_id: item.user_id });
        await this.load();
    }


}

export default PortalSeminifyWidgetSeminarMemberComponent;