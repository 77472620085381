import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.widget.conference.seminar');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-widget-conference-seminar',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.widget.conference.seminar/view.scss */
/* src/app/portal.seminify.widget.conference.seminar/view.scss: no such file or directory */`],
})
export class PortalSeminifyWidgetConferenceSeminarComponent implements OnInit {

    @Input() id: any;
    @Input() data: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async upload(item) {
        let files = await this.service.file.select({ accept: 'application/pdf' });
        if (files.length == 0) return;

        await this.service.status.show('loading');

        let fd = new FormData();
        fd.append('upload', files[0]);
        fd.append('seminar_id', item.id);
        let fdurl = wiz.url("upload");
        const { data } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        item.presentation_file = data;

        await this.service.status.hide('loading');
        await this.service.render();
    }

    public async deleteFile(item) {
        let res = await this.service.modal.warning(`'${item.title}' 발표자료를 삭제하시겠습니까?`, "취소");
        if (!res) return;
        item.presentation_file = null;
        const { code, data } = await wiz.call("update", item);
        for (let key in data)
            item[key] = data[key]
        await this.service.render();
    }

    public async add() {
        this.data.seminar.push({});
        await this.service.render();
    }

    public async deleteToggle(item) {
        item.deleted = !item.deleted;
        await this.service.render();
    }

    public sortableOptions: any = {
        animation: 0,
        handle: '.handle'
    };

    public async report(item: any) {
        if (!item.extra.report) {
            await this.service.status.show('loading');
            const { data } = await wiz.call("report", { conference_id: this.data.conference.id, seminar_id: item.id });
            item.extra.report = data;
            await this.service.status.hide('loading');
        }

        let res = await this.service.modal.show({
            title: '',
            message: item.extra.report,
            cancel: "재생성",
            actionBtn: 'success',
            action: "확인",
            status: 'success'
        });

        if (res) return;

        await this.service.status.show('loading');
        res = await wiz.call("report", { conference_id: this.data.conference.id, seminar_id: item.id });
        item.extra.report = res.data;
        await this.service.status.hide('loading');

        await this.service.modal.show({
            title: '',
            message: item.extra.report,
            cancel: false,
            actionBtn: 'success',
            action: "확인",
            status: 'success'
        });
    }

}

export default PortalSeminifyWidgetConferenceSeminarComponent;