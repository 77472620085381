import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.widget.seminar.survey');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-widget-seminar-survey',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.widget.seminar.survey/view.scss */
/* src/app/portal.seminify.widget.seminar.survey/view.scss: no such file or directory */`],
})
export class PortalSeminifyWidgetSeminarSurveyComponent implements OnInit {
    @Input() conference: any;
    @Input() userdata: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        for (let key in this.userdata.scores) {
            this.userdata.scores[key] = this.userdata.scores[key] + '';
        }

        await this.service.render();
    }

    public disabled() {
        return this.userdata.status == '종료';
    }
}

export default PortalSeminifyWidgetSeminarSurveyComponent;