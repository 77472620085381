import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.widget.seminar.memo');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';

@Component({
    selector: 'wiz-portal-seminify-widget-seminar-memo',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.widget.seminar.memo/view.scss */
/* src/app/portal.seminify.widget.seminar.memo/view.scss: no such file or directory */`],
})
export class PortalSeminifyWidgetSeminarMemoComponent implements OnInit {
    @Input() conference: any;
    @Input() seminar: any;
    @Input() userdata: any;
    @Input() event: any;

    @ViewChild('messages')
    public messageElement: ElementRef;

    public message: string = "";

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.moveLast();
    }

    public async delete(log) {
        let res = await this.service.modal.error("정말로 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        await this.userdata.text.remove(log);
        await this.event.update(false);
        await this.service.render();
    }

    public async deleteAssistant(log) {
        let res = await this.service.modal.error("정말로 삭제하시겠습니까?", "취소", "삭제");
        if (!res) return;
        delete log.assist;
        await this.event.update(false);
        await this.service.render();
    }

    public async update() {
        let page = this.event.getPage();
        let res = await this.autocomplete(page, this.message);
        if (!res) return;
        let obj = { page: page, content: this.message + "", assist: res }
        await this.userdata.text.push(obj);
        await this.event.update(false);
        this.message = "";
        await this.service.render();
        await this.moveLast();
    }

    public async moveLast() {
        await this.service.render();
        let scrollElement = this.messageElement.nativeElement;
        scrollElement.parentElement.parentElement.scrollTo(0, scrollElement.scrollHeight + 32);
    }

    public async autocomplete(page, text) {
        if (!text || text.length < 10) {
            await this.service.modal.error("10글자 이상의 의견을 남겨주세요.");
            return false;
        }
        await this.service.status.show("loading");
        const { data } = await wiz.call("autocomplete", { text: text, page: page, seminar_id: this.seminar.id });
        await this.service.status.hide("loading");
        return data;
    }
}

export default PortalSeminifyWidgetSeminarMemoComponent;