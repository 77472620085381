import '@angular/compiler';
import { PortalSeminifyWidgetConferenceSeminarComponent } from './portal.seminify.widget.conference.seminar/portal.seminify.widget.conference.seminar.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalSeminifyWidgetSeminarSurveyComponent } from './portal.seminify.widget.seminar.survey/portal.seminify.widget.seminar.survey.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalSeminifyConferenceNewComponent } from './portal.seminify.conference.new/portal.seminify.conference.new.component';
import { PortalSeminifyWidgetConferenceSurveyComponent } from './portal.seminify.widget.conference.survey/portal.seminify.widget.conference.survey.component';
import { PortalSeminifyWidgetSeminarMemberComponent } from './portal.seminify.widget.seminar.member/portal.seminify.widget.seminar.member.component';
import { PortalSeminifyConferenceListComponent } from './portal.seminify.conference.list/portal.seminify.conference.list.component';
import { PortalSeasonTreeComponent } from './portal.season.tree/portal.season.tree.component';
import { PageFindpwComponent } from './page.findpw/page.findpw.component';
import { LayoutNavbarColorComponent } from './layout.navbar.color/layout.navbar.color.component';
import { PortalSeasonFormDropdownComponent } from './portal.season.form.dropdown/portal.season.form.dropdown.component';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalSeminifyConferenceItemComponent } from './portal.seminify.conference.item/portal.seminify.conference.item.component';
import { PortalSeminifyConferenceSeminarComponent } from './portal.seminify.conference.seminar/portal.seminify.conference.seminar.component';
import { PageConferenceComponent } from './page.conference/page.conference.component';
import { PortalSeminifyWidgetSeminarMemoComponent } from './portal.seminify.widget.seminar.memo/portal.seminify.widget.seminar.memo.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { PageMainComponent } from './page.main/page.main.component';
import { ComponentLogoComponent } from './component.logo/component.logo.component';
import { PortalSeminifyWidgetSeminarSettingComponent } from './portal.seminify.widget.seminar.setting/portal.seminify.widget.seminar.setting.component';
import { PortalSeasonModalComponent } from './portal.season.modal/portal.season.modal.component';
import { PortalSeminifyWidgetConferenceMemberComponent } from './portal.seminify.widget.conference.member/portal.seminify.widget.conference.member.component';
import { PortalSeminifyWidgetConferenceEditComponent } from './portal.seminify.widget.conference.edit/portal.seminify.widget.conference.edit.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';

const INDEX_PAGE = "main";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "login",
                component: PageLoginComponent,
                "app_id": "page.login"
            },
            {
                "path": "findpw",
                component: PageFindpwComponent,
                "app_id": "page.findpw"
            },
            {
                "path": "join",
                component: PageJoinComponent,
                "app_id": "page.join"
            }
        ]
    },
    {
        component: LayoutNavbarColorComponent,
        "children": [
            {
                "path": "conference/:id?/:seminar?/:sub?",
                component: PageConferenceComponent,
                "app_id": "page.conference"
            }
        ]
    },
    {
        component: LayoutNavbarComponent,
        "children": [
            {
                "path": "main",
                component: PageMainComponent,
                "app_id": "page.main"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }