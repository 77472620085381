import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.conference.list');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-portal-seminify-conference-list',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.conference.list/view.scss */
/* src/app/portal.seminify.conference.list/view.scss: no such file or directory */`],
})
export class PortalSeminifyConferenceListComponent implements OnInit {
    @Input() baseUri: any = "/conference";
    @Input() title: any = "현재 진행중인 컨퍼런스";
    @Input() visibility: any = "apply,public";
    @Input() description: any = "지금 참여할 수 있는 최신 컨퍼런스와 세미나를 확인하세요. 다양한 주제와 전문 연사들이 준비한 컨퍼런스에서 최신 정보를 습득하고, 네트워킹의 기회를 놓치지 마세요. 아래 목록에서 관심 있는 컨퍼런스를 선택하고, 실시간으로 진행되는 흥미로운 세션에 참여해 보세요.";

    public data: any = [];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { data } = await wiz.call("search", { visibility: this.visibility });
        this.data = data.rows;
        await this.service.render();
    }

    public formatter = {
        date: (date, format = "YYYY-MM-DD") => {
            if (!date) return "-";
            let res: any = moment(date).format(format);
            if (res == 'Invalid date') return "-";
            return res;
        }
    }
}

export default PortalSeminifyConferenceListComponent;