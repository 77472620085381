import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.empty');
import { OnInit } from '@angular/core';
import { HostListener } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-layout-empty',
template: templateSource || '',
    styles: [``],
})
export class LayoutEmptyComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.status.hide("loading");
    }

    @HostListener('document:click')
    public clickout() {
        this.service.status.hide("navbar");
    }

    public isActive(link: string) {
        return location.pathname.indexOf(link) === 0
    }
}

export default LayoutEmptyComponent;