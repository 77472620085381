import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/page.main/view.scss */
p.blue {
  color: blue;
}`],
})
export class PageMainComponent implements OnInit {

    public data: any = [];

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        // await this.load();
    }

    public async load() {
        const { data } = await wiz.call("search");
        this.data = data.rows;
        await this.service.render();
    }

}

export default PageMainComponent;