import '@angular/compiler';
import { PortalSeminifyWidgetConferenceSeminarComponent } from './portal.seminify.widget.conference.seminar/portal.seminify.widget.conference.seminar.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PortalSeminifyWidgetSeminarSurveyComponent } from './portal.seminify.widget.seminar.survey/portal.seminify.widget.seminar.survey.component';
import { PageLoginComponent } from './page.login/page.login.component';
import { PortalSeminifyConferenceNewComponent } from './portal.seminify.conference.new/portal.seminify.conference.new.component';
import { PortalSeminifyWidgetConferenceSurveyComponent } from './portal.seminify.widget.conference.survey/portal.seminify.widget.conference.survey.component';
import { PortalSeminifyWidgetSeminarMemberComponent } from './portal.seminify.widget.seminar.member/portal.seminify.widget.seminar.member.component';
import { PortalSeminifyConferenceListComponent } from './portal.seminify.conference.list/portal.seminify.conference.list.component';
import { PortalSeasonTreeComponent } from './portal.season.tree/portal.season.tree.component';
import { PageFindpwComponent } from './page.findpw/page.findpw.component';
import { LayoutNavbarColorComponent } from './layout.navbar.color/layout.navbar.color.component';
import { PortalSeasonFormDropdownComponent } from './portal.season.form.dropdown/portal.season.form.dropdown.component';
import { PortalSeasonPaginationComponent } from './portal.season.pagination/portal.season.pagination.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { PortalSeminifyConferenceItemComponent } from './portal.seminify.conference.item/portal.seminify.conference.item.component';
import { PortalSeminifyConferenceSeminarComponent } from './portal.seminify.conference.seminar/portal.seminify.conference.seminar.component';
import { PageConferenceComponent } from './page.conference/page.conference.component';
import { PortalSeminifyWidgetSeminarMemoComponent } from './portal.seminify.widget.seminar.memo/portal.seminify.widget.seminar.memo.component';
import { PageJoinComponent } from './page.join/page.join.component';
import { PageMainComponent } from './page.main/page.main.component';
import { ComponentLogoComponent } from './component.logo/component.logo.component';
import { PortalSeminifyWidgetSeminarSettingComponent } from './portal.seminify.widget.seminar.setting/portal.seminify.widget.seminar.setting.component';
import { PortalSeasonModalComponent } from './portal.season.modal/portal.season.modal.component';
import { PortalSeminifyWidgetConferenceMemberComponent } from './portal.seminify.widget.conference.member/portal.seminify.widget.conference.member.component';
import { PortalSeminifyWidgetConferenceEditComponent } from './portal.seminify.widget.conference.edit/portal.seminify.widget.conference.edit.component';
import { PortalSeasonLoadingSeasonComponent } from './portal.season.loading.season/portal.season.loading.season.component';
import { PortalSeasonLoadingDefaultComponent } from './portal.season.loading.default/portal.season.loading.default.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { SortablejsModule } from "src/libs/portal/season/ngx-sortablejs";
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

@NgModule({
    declarations: [
        AppComponent,
        PortalSeminifyWidgetConferenceSeminarComponent,
        LayoutNavbarComponent,
        PortalSeminifyWidgetSeminarSurveyComponent,
        PageLoginComponent,
        PortalSeminifyConferenceNewComponent,
        PortalSeminifyWidgetConferenceSurveyComponent,
        PortalSeminifyWidgetSeminarMemberComponent,
        PortalSeminifyConferenceListComponent,
        PortalSeasonTreeComponent,
        PageFindpwComponent,
        LayoutNavbarColorComponent,
        PortalSeasonFormDropdownComponent,
        PortalSeasonPaginationComponent,
        LayoutEmptyComponent,
        PortalSeminifyConferenceItemComponent,
        PortalSeminifyConferenceSeminarComponent,
        PageConferenceComponent,
        PortalSeminifyWidgetSeminarMemoComponent,
        PageJoinComponent,
        PageMainComponent,
        ComponentLogoComponent,
        PortalSeminifyWidgetSeminarSettingComponent,
        PortalSeasonModalComponent,
        PortalSeminifyWidgetConferenceMemberComponent,
        PortalSeminifyWidgetConferenceEditComponent,
        PortalSeasonLoadingSeasonComponent,
        PortalSeasonLoadingDefaultComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        SortablejsModule,
        KeyboardShortcutsModule.forRoot(),
        
    ],
    providers: [{
        provide: COMPOSITION_BUFFER_MODE,
        useValue: false
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }