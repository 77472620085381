import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.logo');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-component-logo',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/component.logo/view.scss */
/* src/app/component.logo/view.scss: no such file or directory */`],
})
export class ComponentLogoComponent implements OnInit {
    @Input() className: any = "h-5 w-auto text-indigo-600";

    public async ngOnInit() {
    }
}

export default ComponentLogoComponent;