import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.widget.seminar.setting');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-widget-seminar-setting',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.widget.seminar.setting/view.scss */
/* src/app/portal.seminify.widget.seminar.setting/view.scss: no such file or directory */`],
})
export class PortalSeminifyWidgetSeminarSettingComponent implements OnInit {
    @Input() conference: any;
    @Input() seminar: any;
    @Input() userdata: any;
    @Input() event: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async upload() {
        let item = this.seminar;

        let files = await this.service.file.select({ accept: 'application/pdf' });
        if (files.length == 0) return;

        await this.service.status.show('loading');

        let fd = new FormData();
        fd.append('upload', files[0]);
        fd.append('seminar_id', item.id);
        let fdurl = wiz.url("upload");
        const { data } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        item.presentation_file = data;

        await this.service.status.hide('loading');
        await this.service.render();

        await this.event.load();
    }

    public async deleteFile() {
        let item = this.seminar;
        let res = await this.service.modal.warning(`'${item.title}' 발표자료를 삭제하시겠습니까?`, "취소");
        if (!res) return;
        item.presentation_file = null;
        const { code, data } = await wiz.call("update", { data: JSON.stringify(item) });
        for (let key in data)
            item[key] = data[key]
        await this.service.render();
        await this.event.load();
    }

}

export default PortalSeminifyWidgetSeminarSettingComponent;