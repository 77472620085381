import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.conference.item');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";
import moment from "moment";

@Component({
    selector: 'wiz-portal-seminify-conference-item',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.conference.item/view.scss */
/* src/app/portal.seminify.conference.item/view.scss: no such file or directory */`],
})
export class PortalSeminifyConferenceItemComponent implements OnInit {
    @Input() baseUri: any = "/conference";
    @Input() id: any;

    public data: any = { conference: {}, seminar: [] };
    public info: any = {};
    public seminar: any = [];
    public survey: any = {};
    public role: any = 'user';
    public isAdmin: boolean = false;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public async load() {
        const { code, data } = await wiz.call("load", { id: this.id });
        if (code != 200) {
            await this.service.href("/");
            return;
        }
        this.info = data.conference;
        this.seminar = data.seminar;
        this.survey = data.survey;
        this.role = data.role;
        this.isAdmin = this.role == 'admin';
        this.service.render();
    }

    public async update() {
        if (this.tab == "edit.table") {
            for (let i = 0; i < this.data.seminar.length; i++)
                this.data.seminar[i].order = i + 1;
            let data = JSON.stringify(this.data.seminar);
            await wiz.call("update_seminars", { conference_id: this.id, data: data });
        } else {
            let data = JSON.stringify(this.data.conference);
            await wiz.call("update", { conference_id: this.id, data: data });
        }

        await this.load();
        this.data.conference = JSON.parse(JSON.stringify(this.info));
        if (this.data.starttime) this.data.starttime = moment(this.data.starttime).format("YYYY-MM-DDTHH:mm");
        if (this.data.endtime) this.data.endtime = moment(this.data.endtime).format("YYYY-MM-DDTHH:mm");
        this.data.seminar = JSON.parse(JSON.stringify(this.seminar));

        await this.service.modal.success("저장되었습니다.");
    }

    public tab: string = "view";

    public activeTabClass(tab: string) {
        if (this.tab == tab)
            return "inline-flex items-center border-b-2 border-indigo-500 px-1 py-3 text-sm font-medium text-gray-900";
        return "inline-flex items-center border-b-2 border-transparent px-1 py-3 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
    }

    public async switchTab(tab: string) {
        if (tab == "view") {
            await this.load();
        }

        if (this.tab == "view") {
            this.data.conference = JSON.parse(JSON.stringify(this.info));
            if (this.data.starttime) this.data.starttime = moment(this.data.starttime).format("YYYY-MM-DDTHH:mm");
            if (this.data.endtime) this.data.endtime = moment(this.data.endtime).format("YYYY-MM-DDTHH:mm");

            this.data.seminar = JSON.parse(JSON.stringify(this.seminar));
        }

        this.tab = tab;
        await this.service.render();
    }

    public async reportView(item: any) {
        if (!item.extra.report) {
            return;
        }

        await this.service.modal.show({
            title: '',
            message: item.extra.report,
            cancel: false,
            actionBtn: 'success',
            action: "확인",
            status: 'success'
        });
    }
}

export default PortalSeminifyConferenceItemComponent;