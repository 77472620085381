import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.widget.conference.edit');
import { OnInit, Input } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-widget-conference-edit',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.widget.conference.edit/view.scss */
/* src/app/portal.seminify.widget.conference.edit/view.scss: no such file or directory */`],
})
export class PortalSeminifyWidgetConferenceEditComponent implements OnInit {
    @Input() id: any;
    @Input() data: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async deleteFeaturedImage() {
        this.data.conference.featured_image = '';
        await this.service.render();
    }

    public async uploadFeaturedImage() {
        let files = await this.service.file.select({ accept: 'image/*', multiple: false });
        if (files.length == 0) return;

        let fd = new FormData();
        fd.append('upload', files[0]);
        let fdurl = '/file/upload';
        const { url } = await this.service.file.upload(fdurl, fd, async (percent: number, total: number, position: number) => { });
        this.data.conference.featured_image = url;
        this.service.render();
    }

    public timestamp() {
        return new Date().getTime();
    }

}

export default PortalSeminifyWidgetConferenceEditComponent;