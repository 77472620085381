import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.conference');
import { OnInit, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-conference',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/page.conference/view.scss */
/* src/app/page.conference/view.scss: no such file or directory */`],
})
export class PageConferenceComponent implements OnInit, DoCheck {
    constructor(@Inject( Service) public service: Service) { }

    public id: any;
    public seminar: any;
    public sub: any;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, "/");

        this.id = WizRoute.segment.id;
        this.seminar = WizRoute.segment.seminar;
        this.sub = WizRoute.segment.sub;

        await this.service.render();
    }

    public async ngDoCheck() {
        this.id = WizRoute.segment.id;
        this.seminar = WizRoute.segment.seminar;
        this.sub = WizRoute.segment.sub;
    }

}

export default PageConferenceComponent;