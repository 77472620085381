import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.seminify.conference.seminar');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-seminify-conference-seminar',
template: templateSource || '',
    styles: [`

/* file: /opt/seminify/project/main/build/src/app/portal.seminify.conference.seminar/view.scss */
/* src/app/portal.seminify.conference.seminar/view.scss: no such file or directory */`],
})
export class PortalSeminifyConferenceSeminarComponent implements OnInit {
    @Input() conference_id: any;
    @Input() seminar_id: any;

    public page: number = 1;
    public hide: boolean = false;

    public conference: any = {};
    public seminar: any = {};
    public survey: any;
    public role: any = "user";
    public event: any = {};
    public shortcuts: any = [];

    public loading: boolean = true;
    public isFullScreen: boolean = false;
    public timestamp: any;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        this.timestamp = new Date().getTime();
        this.event.move = this.move.bind(this);
        this.event.update = this.update.bind(this);
        this.event.getPage = this.getPage.bind(this);
        this.event.updateTimestamp = this.updateTimestamp.bind(this);
        this.event.load = this.load.bind(this);
        await this.load();
        await this.bindShortcut();
        this.loading = false;
        await this.service.render();
    }

    public getPage() {
        return this.page;
    }

    public disabled() {
        return this.survey.status == '종료';
    }

    public async request(uri: string, data: any = {}) {
        data.conference_id = this.conference_id;
        data.seminar_id = this.seminar_id;
        return await wiz.call(uri, data);
    }

    public async load() {
        this.loading = true;
        await this.service.render();
        const { data } = await this.request("load");
        this.role = data.role;
        this.conference = data.conference;
        this.seminar = data.seminar;
        this.survey = data.survey;
        await this.updateTimestamp();
        this.loading = false;
        await this.service.render();
    }

    public updateTimestamp() {
        this.timestamp = new Date().getTime();
        this.service.render();
    }

    public viewuri() {
        let timestamp = this.timestamp;
        return wiz.url(`view?conference_id=${this.conference_id}&seminar_id=${this.seminar_id}&page=${this.page}&time=${timestamp}`);
    }

    public async move(page) {
        if (page == 0) return;
        if (page > this.seminar.presentation_file * 1) return;

        this.hide = true;
        this.service.render();

        this.hide = false;
        this.page = page;
        this.service.render();
    }

    public async update(isEnd: boolean = true) {
        if (isEnd) {
            let res = await this.service.modal.warning("평가를 종료하면 내용을 변경할 수 없습니다. 정말로 종료하시겠습니까?", "취소");
            if (!res) return;
            this.survey.status = '종료';
        }

        let survey = JSON.stringify(this.survey);
        await this.request("update", { data: survey });

        if (isEnd) {
            await this.service.modal.success("평가가 종료되었습니다.");
        } else {
            await this.service.modal.success("저장되었습니다.");
        }
    }

    public tab: string = "memo";

    public async switchTab(tab: string) {
        this.tab = tab;
        await this.service.render();
    }

    public activeClass(tab: string) {
        if (this.tab == tab)
            return "inline-flex items-center border-b-2 border-indigo-500 px-1 py-3 text-sm font-medium text-gray-900";
        return "inline-flex items-center border-b-2 border-transparent px-1 py-3 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700";
    }

    public async fullscreen() {
        this.isFullScreen = true;
        await this.service.render();
    }

    public async bindShortcut() {
        this.shortcuts.push({
            key: ["alt + f"],
            preventDefault: true,
            command: async () => {
                this.isFullScreen = true;
                await this.service.render();
            }
        });

        this.shortcuts.push({
            key: ["tab"],
            preventDefault: true,
            command: async () => {
                this.isFullScreen = !this.isFullScreen;
                await this.service.render();
            }
        });

        this.shortcuts.push({
            key: ["esc"],
            preventDefault: true,
            command: async () => {
                this.isFullScreen = false;
                await this.service.render();
            }
        });

        this.shortcuts.push({
            key: ["right", "pagedown"],
            preventDefault: true,
            command: async () => {
                await this.move(this.page + 1);
            }
        });

        this.shortcuts.push({
            key: ["left", "pageup"],
            preventDefault: true,
            command: async () => {
                await this.move(this.page - 1);
            }
        });

        await this.service.render();
    }

}

export default PortalSeminifyConferenceSeminarComponent;